/**
 * @file
 * @copyright  2013 Michael Aufreiter (Development Seed) and 2016 Yahoo Inc.
 * @license    Licensed under {@link https://spdx.org/licenses/BSD-3-Clause-Clear.html BSD-3-Clause-Clear}.
 *             Github.js is freely distributable.
 */

import Requestable from './Requestable';

/**
 * RateLimit allows users to query their rate-limit status
 */
class RateLimit extends Requestable {
   /**
    * construct a RateLimit
    * @param {Requestable.auth} auth - the credentials to authenticate to GitHub
    * @param {string} [apiBase] - the base Github API URL
    * @return {Promise} - the promise for the http request
    */
   constructor(auth, apiBase) {
      super(auth, apiBase);
   }

   /**
    * Query the current rate limit
    * @see https://developer.github.com/v3/rate_limit/
    * @param {Requestable.callback} [cb] - will receive the rate-limit data
    * @return {Promise} - the promise for the http request
    */
   getRateLimit(cb) {
      return this._request('GET', '/rate_limit', null, cb);
   }
}

module.exports = RateLimit;
